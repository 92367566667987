<template>
<div>
  <div v-for="(set, index) in rows" :key="'section-top-' + index">
    <div v-if="set.term">
      <div v-if="set.data.length > 0">
        <table v-if="level === 1" style="font-family: Arial; background-color: #0c233c; color: white;" width=100%>
            <tr>
              <td style="background-color: #0c233c; color: white; padding: 10px 5px 10px 5px;">
                <h1 style="color: white; font-size: 16px; font-weight: 700;">{{set.term}}</h1>
              </td>
            </tr>
        </table>
        <table v-if="level === 2" style="font-family: Arial; background-color: #00348d; color: white;" width=100%>
            <tr>
              <td style="background-color: #00348d; color: white; padding: 10px 5px 10px 5px;">
                <h2 style="color: white; font-size: 14px; font-weight: 700;">{{set.term}}</h2>
              </td>
            </tr>
        </table>
        <table v-if="level === 3" style="font-family: Arial; background-color: #1e49e2; color: white;" width=100%>
            <tr>
              <td style="background-color: #1e49e2; color: white; padding: 10px 5px 10px 5px;">
                <h2 style="color: white; font-size: 14px; font-weight: 700;">{{set.term}}</h2>
              </td>
            </tr>
        </table>
        <br />
        <initiative-recursive
          :rows="set.data"
          :options="options"
          :level="level + 1"
        ></initiative-recursive>
        <br />
      </div>
    </div>
    <div v-else>
          <initiative-report
            :item="set"
            :options="options"
            heading="h3"
          ></initiative-report>
    </div>
  </div>
</div>
</template>

<script>
import InitiativeReport from '@/components/InitiativeReportCPRD'

export default {
  name: 'initiative-recursive',
  props: {
    rows: {
      type: Array,
      required: true
    },
    level: {
      type: Number,
      required: false,
      default: 1
    },
    options: {
      type: Object,
      required: true
    }
  },
  components: {
    InitiativeReport
  },
  data () {
    return {
    }
  }
}
</script>

<style>
</style>
