<template>
<div>
  <b-container fluid>
    <b-row class="my-2">
      <b-col class="text-center">
        <b-card>
          <b-form inline>
            Group by:
            <b-form-select class="ml-2 mr-2" v-model="level1" :options="level1options"></b-form-select>
            <b-form-select class="mr-2" v-model="level2" :options="level2options"></b-form-select>
            <b-form-select v-if="level2" class="mr-2" v-model="level3" :options="level3options"></b-form-select>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="my-2">
      <b-col class="text-center">
        <b-card>
          <b-form inline class="mb-2">
            <b-button class="mr-2" v-b-toggle.sidebar-1>Report options</b-button>
            <b-button @click="generateReport">Download</b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-container>
          <b-card>
            <div id="exportElement">
              <initiative-recursive
                :rows="grouped"
                :options="options"
              ></initiative-recursive>
            </div>
          </b-card>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
  <b-sidebar id="sidebar-1" title="Report settings" shadow>
    <div class="px-3 py-1" v-for="(row, index) in options" :key="'options-' + index">
      <b-form-checkbox v-model="row.value" :value="true" :unchecked-value="false">{{row.text}}</b-form-checkbox>
    </div>
  </b-sidebar>
</div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import workflow from '@/workflows/initiative'

import InitiativeRecursive from '@/components/InitiativeReportCPRDRecursive'

const structure = {
  name: 'initiatives',
  schema: [
    {
      id: 'area',
      dictionary: 'area',
      displayName: 'policy area',
      property: 'area',
      type: 'standardObject',
      prefix: ''
    },
    {
      id: 'country',
      dictionary: 'countries',
      displayName: 'country',
      property: 'country',
      type: 'singleField'
    },
    {
      id: 'expert',
      dictionary: 'experts',
      displayName: 'experts',
      property: 'impactexperts',
      type: 'standardArray'
    },
    {
      id: 'impactstatus',
      dictionary: 'impactstatus',
      displayName: 'implementation status',
      property: 'impactstatus',
      type: 'standardObject',
      prefix: 'Implementation: '
    }
  ]
}

const extractors = {
  singleField: function (field, dictionary, data) {
    const results = []
    _.each(dictionary, term => {
      const row = {
        term: term,
        data: _.filter(data, x => {
          return x[field.property] === term
        })
      }
      results.push(row)
    })
    return results
  },
  standardArray: function (field, dictionary, data) {
    const results = []
    _.each(dictionary, term => {
      const row = {
        term: term,
        data: _.filter(data, x => {
          return _.find(x[field.property], y => y.name === term)
        })
      }
      if (row.data.length > 0) {
        results.push(row)
      }
    })
    const otherRows = {
      term: 'Not defined',
      data: _.filter(data, x => {
        return x[field.property].length === 0
      })
    }
    if (otherRows.data.length > 0) {
      results.push(otherRows)
    }
    return results
  },
  standardObject: function (field, dictionary, data) {
    const results = []
    _.each(dictionary, term => {
      const row = {
        term: term,
        data: _.filter(data, x => {
          if (x[field.property] !== null) {
            return field.prefix + x[field.property].name === term
          } else {
            return field.prefix + 'not defined' === term
          }
        })
      }
      results.push(row)
    })
    return results
  },
  multipleBooleanFields: function (field, dictionary, data) {
    const results = []
    _.each(dictionary, term => {
      const row = {
        term: term.displayName,
        data: _.filter(data, x => x[term.property])
      }
      if (row.data.length > 0) {
        results.push(row)
      }
    })
    const otherRows = {
      term: 'Not defined',
      data: []
    }
    _.each(data, row => {
      let belongs = false
      _.each(dictionary, term => {
        if (row[term.property]) {
          belongs = true
        }
      })
      if (!belongs) {
        otherRows.data.push(row)
      }
    })
    if (otherRows.data.length > 0) {
      results.push(otherRows)
    }
    return results
  }
}

const dictionaryMakers = {
  singleField: function (data, field) {
    let options = data.map(row => {
      if (row[field.property]) {
        return row[field.property]
      } else {
        return 'no ' + field.displayName
      }
    })
    options = _.uniq(options)
    options.sort()
    return options
  },
  standardArray: function (data, field) {
    let options = []
    _.each(data, x => {
      _.each(x[field.property], y => {
        options.push(y.name)
      })
    })
    options = _.uniq(options)
    options.sort()
    return options
  },
  standardObject: function (data, field) {
    let options = data.map(row => {
      if (row[field.property] !== null) {
        return field.prefix + row[field.property].name
      } else {
        return field.prefix + 'not defined'
      }
    })
    options = _.uniq(options)
    options.sort()
    return options
  },
  multipleBooleanFields: function (data, field) {
    return field.properties
  }
}

export default {
  name: 'InitiativeReportingMain',
  components: {
    InitiativeRecursive
  },
  computed: {
    dictionaries: function () {
      const allData = this[structure.name]
      const result = []
      if (allData.length > 0) {
        _.each(structure.schema, field => {
          const options = dictionaryMakers[field.type](allData, field)
          result[field.dictionary] = options
        })
      }
      return result
    },
    initiatives: {
      get () {
        return this.$store.state.initiativesPoliciesShow
      }
    },
    grouped: function () {
      const level1 = this.level1
      const level2 = this.level2
      const level3 = this.level3
      const level1field = structure.schema.find(x => x.id === level1)
      const results = extractors[level1field.type](level1field, this.dictionaries[level1field.dictionary], this.initiatives)

      if (level2) {
        const level2field = structure.schema.find(x => x.id === level2)
        _.each(results, set => {
          set.data = extractors[level2field.type](level2field, this.dictionaries[level2field.dictionary], set.data)
          if (level3) {
            const level3field = structure.schema.find(x => x.id === level3)
            _.each(set.data, set3 => {
              set3.data = extractors[level3field.type](level3field, this.dictionaries[level3field.dictionary], set3.data)
            })
          }
        })
      }
      return results
    },
    level1options: function () {
      return structure.schema.map(x => {
        return { value: x.id, text: x.displayName }
      })
    },
    level2options: function () {
      let response = [{ value: null, text: 'none selected' }].concat(structure.schema.map(x => {
        return { value: x.id, text: x.displayName }
      }))
      _.remove(response, x => {
        return x.value === this.level1
      })
      return response
    },
    level3options: function () {
      let response = [{ value: null, text: 'none selected' }].concat(structure.schema.map(x => {
        return { value: x.id, text: x.displayName }
      }))
      _.remove(response, x => {
        return x.value === this.level1
      })
      _.remove(response, x => {
        return x.value === this.level2
      })
      return response
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      config: {
        dates: {
          articles: {
            start: '2010-01-01',
            end: '2022-12-31'
          },
          events: {
            start: '2010-01-01',
            end: '2030-12-31'
          },
          publications: {
            start: '2010-01-01',
            end: '2022-12-31'
          }
        }
      },
      counter: 0,
      form: {
        function: '',
        industry: null,
        industrygroup: null,
        reason: '',
        reasonother: '',
        sector: null,
        size: null,
        subindustry: null
      },
      industry: '',
      industryGroup: '',
      initiativesFull: [],
      initiativesObligations: [],
      level1: structure.schema[0].id,
      level2: null,
      level3: null,
      loading: false,
      mandatory: [
        {
          key: 'mandatory',
          name: 'Mandatory'
        },
        {
          key: 'optional',
          name: 'Optional'
        },
        {
          key: 'complyexplain',
          name: 'Comply or explain'
        },
        {
          key: 'other',
          name: 'Other'
        }
      ],
      moment: moment,
      options: {
        country: { value: true, text: 'country and status' },
        policyDetails: { value: true, text: 'policy table' },
        descriptionInitiative: { value: true, text: 'description - initiative' },
        experts: { value: true, text: 'experts' },
        externalLinks: { value: true, text: 'external links' },
        headline: { value: true, text: 'headline' },
        publications: { value: true, text: 'publications' }
      }
    }
  },
  created: async function () {
    document.title = "Initiatives Reporting"
    this.workflow = workflow
    await this.$nextTick()
  },
  methods: {
    generateReport: function () {
      this.$stat.log({ page: 'CPRD reporting', action: 'download CPRD Grouped report', payload: { reportType: this.reportSelected } })
      const start = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
      <head>
        <meta charset='utf-8'>
        <title>Export HTML To Doc</title>
        <style>
          body {
            font-family: arial;
          }
          h1, h2, h3, h4, h5 {
            color: #00338D;
          }
          h4 {
            margin-top: 18px;
            margin-bottom: 6px;
          }
          h5 {
            font-size: 1em;
          }
          table {
            border: 1px solid #00338D;
            border-collapse: collapse;
            font-size: 8px;
          }
          td {
            border: 1px solid #00338D;
            border-collapse: collapse;
            padding: 5px;
            vertical-align: top;
          }
          .mb-2 {
            margin-bottom: 6px;
          }
          .section {
            color: white;
            background-color: #00338D;
          }
        </style>
      </head>
      <body>
      `
      const end = `
      </body></html>
      `
      const html = start + document.getElementById('exportElement').innerHTML + end
      // const blob = new Blob(['\ufeff', html], { type: 'application/msword' })
      const url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html)
      const filename = 'cprd-download' + moment().toISOString() + '.doc'
      const link = document.createElement('a')
      link.href = url
      link.download = filename
      link.click()
    }
  },
  watch: {
  }
}
</script>
<style>
  #exportElement > h1, h2, h3, h4 {
    color: #00338D;
  }
  #exportElement h4 {
    margin-top: 18px;
    margin-bottom: 6px;
  }
  #exportElement .section {
    color: white;
    background-color: #00338D;
  }
</style>
